import React, { Component } from 'react'
import { Typography, Grid } from '@material-ui/core';
import RootGrid from './RootGrid'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <RootGrid>
          <Grid container justify='center'>
            <Grid item xs={12}>
              <Typography variant='h2'>Algo salió mal.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h1'>Intente de nuevo más tarde</Typography>
            </Grid>
          </Grid>
        </RootGrid>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
