import React, { Component } from 'react'
import { TextField, Grid, Paper, Typography, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Swal from 'sweetalert2';

const styles = theme => ({
  root: {
    minHeight: '75vh'
  },
  paper: {
    padding: '3% 1%',
    width: '90%',
    margin: '5% 0',
    [theme.breakpoints.up('sm')]: {
      padding: '5% 7%',
      width: '60%'
    }
  },
  textField: {
    margin: '5% 0'
  }
})

export default withStyles(styles)(class Start extends Component {
  state = {
    validation: ''
  }

  handleChange = ({target: {value}}) => {
    this.setState({validation: value})
  }

  _saveUserData = validation => {
    localStorage.setItem('test-valid', validation)
    this.props.history.push(`/`)
    this.props.history.go('/')
  }
  validate = () => {
    if (this.state.validation === 'V3rsi0n Pru3ba')
      this._saveUserData(this.state.validation)
    else Swal.fire('ERROR', 'Acceso incorrecto', 'error')
  }

  render(){
    return (
      <Grid container direction='column' alignItems='center' justify='center' className={this.props.classes.root}>
        <Paper className={this.props.classes.paper}>
          <Grid container direction='column' alignItems='center' justify='center'>
            <Typography variant='h4' color='primary'>VERSIÓN DE PRUEBA</Typography>
            <Typography variant='h6' color='textSecondary' paragraph>
              Ésta es una versión de prueba, todos los datos que hay en la plataforma son ficticios.
              El sistema de pagos está en versión de prueba por lo que ningún cobro será realizado.
            </Typography>
            <TextField
              variant='outlined'
              value={this.state.validation}
              label='CÓDIGO DE VALIDACIÓN'
              onChange={this.handleChange}
              className={this.props.classes.textField}
            />
            <Button variant='contained' color='primary' onClick={this.validate}>INGRESAR A VERSIÓN DE PRUEBA</Button>
          </Grid>
        </Paper>
      </Grid>
    )
  }
})