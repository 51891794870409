import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#CE7328'
    },
    secondary: {
      main: '#334A5C'
    },
    background: {
      paper: '#DADADA',
      default: '#333333D9'
    },
    common: {
      white: '#EDEDEDbf',
      whiteBackground: '#EDEDED',
      blue: '#304B5D',
      red: '#CD5657'
    },
    text: {
      primary: '#333333',
      secondary: '#878787'
    },
    table: {
      dark: '#CECECE',
      light: '#EDEDED',
      darkContent: '#575756',
      lightContent: '#878787'
    },
    header: '#333333'

  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Montserrat',
      'Source Sans Pro',
      'sans-serif'
    ].join(','),
    // Section title
    h1: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(','),
      fontWeight: 500,
      fontSize: 32,
      color: '#878787',
      paddingBottom: 4
    },
    // Subsection title
    h2: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(','),
      fontWeight: 300,
      fontSize: 32,
      color: '#304B5D'
    },
    // Instructions
    h3: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(','),
      fontWeight: 600,
      fontSize: 27,
      color: '#878787',
      marginLeft: 24
    },
    // Submenu
    h4: {
      fontFamily: [
        'Source Sans Pro',
        'sans-serif'
      ].join(','),
      fontWeight: 400,
      fontSize: 24,
      color: '#304B5D'
    },
    // Header Menu Items
    h5: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(','),
      fontWeight: 300,
      fontSize: 18,
      color: '#EDEDEDaa'
    },
    // Username in header && Step number title
    h6: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(','),
      fontWeight: 600,
      fontSize: 24,
      color: '#878787'
    },
    // Table head
    subtitle1: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(','),
      fontWeight: 300,
      fontSize: 24,
      color: '#304B5D'
    },
    // Orange details below car info
    subtitle2: {
      fontFamily: [
        'Source Sans Pro',
        'sans-serif'
      ].join(','),
      fontWeight: 600,
      fontStyle: 'italic',
      fontSize: 24,
      color: '#CE7328'
    },
    // Car info/details && (Rendimiento, Consumo mensual, Consumo anual)
    body1: {
      fontFamily: [
        'Source Sans Pro',
        'sans-serif'
      ].join(','),
      fontWeight: 400,
      fontSize: 20,
      color: '#878787'
    },
    body2: {
      color: '#878787',
      lineHeight: 2
    },
    // Self explanatory
    button: {
      fontFamily: [
        'Montserrat',
        'sans-serif'
      ].join(','),
      fontWeight: 400,
      fontSize: 16,
      color: '#EDEDED',
      textTransform: 'none',
      margin: '16px 0',
      borderRadius: 8
    },
    // Footer && TableRow Content (Change colors of tablerow content)
    caption: {
      fontFamily: [
        'Source Sans Pro',
        'sans-serif'
      ].join(','),
      fontSize: 16,
      fontWeight: 300,
      color: '#EDEDED'
    },
    // Text input placeholders
    textField: {
      fontFamily: [
        'Source Sans Pro',
        'sans-serif'
      ].join(','),
      fontWeight: 400,
      fontStyle: 'italic',
      fontSize: 24
    }
  },
  shape: {
    borderRadius: 14
  },
  overrides: {
    MuiToolbar: {
      gutters: {
        paddingLeft: 16,
        paddingRight: 16,
        '@media (min-width:600px)': {
          paddingLeft: 56,
          paddingRight: 56
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0,0,0,0)'
        },
        '&:hover $notchedOutline': {
          borderColor: '#878787aa',
          zIndex: 100
        },
        '&$focused $notchedOutline': {
          borderColor: '#878787aa',
          borderWidth: '0.5px'
        },
        '&$disabled $notchedOutline': {
          borderColor: 'rgba(0,0,0,0)'
        }
      },
      input: {
        fontSize: 14,
        padding: '12px 15px'
      },
      notchedOutline: {
        borderRadius: 8
      },
      multiline: {
        height: '100%'
      }
    },
    MuiFormControl: {
      root: {
        borderRadius: 8,
        margin: '16px 0 8px 0'
      }
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: 'none',
          transition: 'none'
        },
        '&:before': {
          borderBottom: 'none',
          transition: 'none'
        },
        '&:hover:not($disabled):before': {
          borderBottom: '.5px solid #878787aa',
          transition: 'none',
          borderColor: '#878787aa',
          border: '.5px solid',
          height: '100%',
          borderRadius: 8
        },
        '&$disabled:before': {
          borderBottom: 'none',
          transition: 'none'
        }
      }
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          fontStyle: 'italic'
        }
        // color: '#333333',
      }
    },
    MuiInputLabel: {
      root: { // Name of the rule
        '&$focused': { // increase the specificity for the pseudo class
          color: '#CE7328'
        }
      },
      outlined: {
        transform: 'translate(14px, 11px) scale(1)',
        fontSize: 16,
        fontStyle: 'italic',
        '&$shrink': {
          transform: 'translate(0px, -20px) scale(1)',
          fontStyle: 'normal'
        }
      }
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: undefined
        }
      }
    },
    MuiSelect: {
      select: {
        padding: undefined,
        height: undefined
      },
      selectMenu: {
        height: undefined,
        lineHeight: undefined
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: 16,
        minHeight: 32,
        whiteSpace: 'wrap'
      }
    },
    MuiTable: {
      root: {
        fontFamily: [
          'Montserrat',
          'Source Sans Pro',
          'sans-serif'
        ].join(','),
        fontSize: 12
      }
    },
    MuiTableHead: {
      root: {
        '& tr': {
          backgroundColor: '#CECECE',
          '& th': {
            fontSize: '15px !important',
            color: '#334A5C'
          }
        }
      }
    },
    MuiTableBody: {
      root: {
        '& tr': {
          '&:nth-of-type(odd)': {
            backgroundColor: '#EDEDED',
            '& td': {
              color: '#878787'
            }
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#CECECE',
            '& td': {
              color: '#575756'
            }
          },
          '&:nth-of-type(2n+1)': {
            '& td': {
              border: 'none',
              height: 'inherit'
            }
          },
          '&:nth-of-type(2n)': {
            '& td': {
              border: 'none',
              height: 'inherit'
            }
          },
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#CE732899 !important',
            '& td': {
              color: '#EDEDED !important'
            }
          }
        }
      }
    },
    MuiTableRow: {
      footer: {
        height: '100%',
        '& .mt-MuiTableCell-root': {
          border: 'none'
        }
      },
      root: {
        height: '100%',
        '& .mt-MuiTableCell-root': {
          fontSize: 12,
          lineHeight: 1.5
        }
      }
    },
    MuiTablePagination: {
      toolbar: {
        height: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        paddingLeft: 0
      }
    }
  }
})

export default theme
