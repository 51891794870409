import React, { useReducer, useEffect } from "react"

let reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.value }
    case 'remove':
      localStorage.removeItem('user')
      return initialState
    default: break
  }
}

const localRand = localStorage.getItem('rand')
const initialState = { plan: false, rand: '' }
const localState = JSON.parse(localStorage.getItem('user'))

const UserContext = React.createContext();

const UserProvider = props => {
  const [user, setUser] = useReducer(reducer, localState || initialState)

  useEffect( () => {
    localStorage.setItem('user', JSON.stringify(user))
  }, [user])
  
  useEffect( () => {
    if (localRand && !initialState.rand) {
      user.rand = localRand
    }
    localStorage.setItem('user', JSON.stringify(user))
  })

  useEffect( () => {
    if (localRand && !initialState.rand) {
      user.rand = localRand
    }
    localStorage.setItem('user', JSON.stringify(user))
  })

  return (
    <UserContext.Provider value={{ user, setUser }}>
      { props.children }
    </UserContext.Provider>
  )
}

export { UserContext, UserProvider }
