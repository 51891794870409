// React
import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
// PropTypes
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flex: '1 1 auto',
    padding: '5%',
    margin: '0 auto',
    width: 'calc(100% - 16px)',
    maxWidth: '1600px'
  },
  rootNoPadding: {
    flex: '1 1 auto',
    maxWidth: '1600px',
    margin: '0 auto'
  }
}))

const RootGrid = props => {
  const classes = useStyles()
  const {
    children,
    padding,
    ...rest
  } = props

  const rootClass = padding ? classes.root : classes.rootNoPadding
  return (
    <Grid container direction='column' className={rootClass} {...rest}>
      {children}
    </Grid>
  )
}

RootGrid.propTypes = {
  padding: PropTypes.bool
}

export default RootGrid
